var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",staticStyle:{"border-left":"3px solid #5bb55f"},attrs:{"tile":"","elevation":"1","min-height":"280"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"toolbar-work-place-title"},[_vm._v(" Перелік відповідальних осіб ")]),_c('v-spacer'),_c('MainModal',{attrs:{"main":{ component: 'CONTRACTOR_RESPONSE_PERSON_ELEMENTS', title: 'Відповідальна особа' },"button":{ icon: true, color: 'grey lighten-4', buttonIcon: 'mdi-plus' },"item":{
                    person_hash: _vm.flat.person_hash,
                    contractor_name: _vm.flat.full_address,
                  }},on:{"updateItemModal":_vm.updateItemModal}})],1)],1),_c('v-data-table',{staticClass:"custom-table custom-table-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"hide-default-footer":""},on:{"click:row":_vm.openModal},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('MainModal',{attrs:{"main":{ component: 'CONTRACTOR_RESPONSE_PERSON_ELEMENTS', title: 'Відповідальна особа' },"button":{
                    iconActivator: true,
                    class: 'mr-2',
                    color: 'success',
                    buttonIcon: 'mdi-playlist-check',
                    iconSize: 26
                   },"item":{
                    id: item.id,
                    contractor_name: _vm.flat.full_address,
                    contractor_id: item.contractor_id,
                    category: item.category,
                    position_id: item.position_id,
                    contact_person_id: item.contact_person_id,
                    date_start: item.date_start,
                  },"modal":_vm.dialog_id === item.id},on:{"updateItemModal":_vm.updateItemModal}})]}},{key:"item.category",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.getCategoryName(item.category))+" ")])]}},{key:"item.date_start",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_start))+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }